import './Machines.scss';

export default function Machines()
    {
        return(
            <main id="Machines">
                <h1>Le Z80</h1>
                <p>Voici une liste non exhaustive des machines à base de Z80.<br/>
                Ce processeur ayant été utilisé dans diverses choses, il est difficile d'en faire une liste complète.</p>
                <ul>
                    <li>Amstrad - CPC</li>
                    <li>Amstrad - PCW</li>
                    <li>Lansay - Enterprise (commercialisé à l'origine pas Enterprise Computers)</li>
                    <li>Mattel - Aquarius</li>
                    <li>MSX / MSX2</li>
                    <li>Philips - VG5000</li>
                    <li>Sinclair - Timex 1000</li>
                    <li>Sinclair - ZX80</li>
                    <li>Sinclair - ZX81</li>
                    <li>Sinclair - ZX Spectrum</li>
                    <li>Tatung - Einstein</li>
                    <li>VTech - Laser</li>
                </ul>
                <ul>
                    <li>Amstrad - GX4000</li>
                    <li>Coleco - ColecoVision</li>
                    <li>Sega - Game Gear</li>
                    <li>Sega - Master System</li>
                    <li>Yeno - Professeur Saitout / VTech Socrates</li>
                </ul>
            </main>
        )
    }