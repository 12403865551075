import './Footer.scss'


export default function Footer()
{
    return(
        <footer>
            © Durand Amaury 2023
        </footer>
    )
}