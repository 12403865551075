import {Routes, Route} from 'react-router-dom'
import Home from "./pages/Home/Home";
import Z80 from './pages/Z80/Z80';
import About from './pages/About/About';
import Machines from './pages/Machines/Machines';


export default function RoutesList()
    {
        return(
            <Routes>
                <Route path="/APropos" element={<About/>} />
                <Route path="/z80" element={<Z80/>} />
                <Route path="/" element={<Home/>} />
                <Route path="/machines" element={<Machines/>}>Machines</Route>
                {/* <Route path="*" element={<P404/>} />         */}
            </Routes>
        )
    }
