import { Link } from 'react-router-dom'
import './NavBar.scss'



export default function()
    {
        return(
            <nav id="NavBar">
                <Link to={`/`}><button>Annuaire</button></Link>
                <Link to={`/z80`}><button>Le Z80</button></Link>
                <Link to={`/machines`}><button>Machines</button></Link>
                <Link to={`/APropos`}><button>A propos</button></Link>
            </nav>
        )
    }