import "./Z80.scss"

export default function Z80()
    {
        return(
            <main id="Z80">
                <h1>Le Z80</h1>
                <p>Le processeur Z80 est un microprocesseur 8 bits largement utilisé dans les années 1970 et 1980. Il a été développé par Zilog, une entreprise fondée par Federico Faggin et d'autres anciens employés de chez Intel.<br/>
                Le Z80 était une évolution du processeur 8080 d'Intel, mais il offrait des fonctionnalités et des performances améliorées.<br/>
                Il a été utilisé dans de nombreux ordinateurs et systèmes populaires, tels que le Sinclair ZX Spectrum, les Amstrad CPC, les MSX, ainsi que dans diverses machines d'arcade.</p>

                <h2>Caractéristiques techniques :</h2>

                <p>Architecture : Le Z80 possède une architecture de type Von Neumann, ce qui signifie qu'il traite les données et les instructions de manière séquentielle à partir de la mémoire.<br/>
                - Il dispose d'un bus de données de 8 bits, ce qui signifie qu'il peut traiter des données binaires sur 8 bits à la fois.
                - Le Z80 comprend plusieurs registres, dont un registre d'accumulation (A), deux registres d'index (IX et IY), des registres de données (B, C, D, E, H, L), et un registre de compteur de programme (PC) qui stocke l'adresse mémoire de l'instruction en cours.</p>
                
                <p>La vitesse d'horloge typique du Z80 était de 4 MHz, mais des variantes plus rapides ont également été développées.</p>

                <h2>Caractéristiques et fonctionnalités notables :</h2>

                <p>Le Z80 a introduit de nombreuses nouvelles instructions par rapport à son prédécesseur, le 8080 dont des instructions de manipulation des bits et des instructions d'arithmétique améliorées.</p>

                <p>Le Z80 offrait plusieurs modes d'adressage, y compris l'adressage direct, l'adressage indirect, l'adressage indexé et l'adressage relatif, ce qui permettait de réaliser des opérations plus complexes et plus efficaces.</p>

                <p>Le Z80 était en grande partie compatible avec le 8080, ce qui signifie que les programmes écrits pour le 8080 pouvaient généralement être exécutés sans modification sur un Z80.</p>

                <p>Le Z80 comportait des broches spéciales pour les entrées/sorties, ce qui permettait aux périphériques externes d'être connectés directement au processeur.</p>

                <h2>L'héritage du Z80 :</h2>

                <p>Le Z80 a été un processeur extrêmement populaire et a influencé de nombreuses générations de concepteurs de microprocesseurs.<br/>
                Son architecture et ses caractéristiques ont été utilisées comme base pour de nombreux processeurs ultérieurs.<br/>
                Par exemple, le processeur Intel 8086, qui a été utilisé dans le premier IBM PC, a été en partie inspiré par le Z80.</p>

                <p>En conclusion, le processeur Z80 a joué un rôle crucial dans le développement des ordinateurs personnels et des systèmes embarqués des années 1970 et 1980.<br/>
                Sa puissance de calcul, ses fonctionnalités étendues et sa compatibilité avec les précédents processeurs en ont fait un choix populaire pour de nombreuses applications à cette époque.</p>
            </main>
        )
    }