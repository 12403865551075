
import './App.scss';
import RoutesList from './Routes';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';


function App() {
  return (
    <>
      <Header/>

      <RoutesList/>

      <Footer/>
    </>
  );
}

export default App;
