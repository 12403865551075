import './Picture.scss'


interface props 
    {
        source: any;
        descritpion: string;
    }

export default function Picture(props:props)
{
    const source = props.source;
    const description = props.descritpion;
    return (
        <div className="Picture_div">
            <img src={source}alt={description}/>
        </div>
    )
}