import './Logo.scss';
import z80 from './Assets/z80.png'
import logo from './Assets/logo.png'

export default function Logo ()
    {
        return (
            <div id="Logo">
                <div id="Logo_image">
                    <img 
                        src={z80} 
                        alt="z80 component"
                    />
                </div>
                <div id="Logo_titre">
                    <img 
                        src={logo}
                        alt="zilog.fr logo" 
                    />
                </div>
            </div>
        )
    }