import Picture from "../../components/Picture/Picture"
import "./About.scss"
import Matete from "./Assets/Amaury.jpg"

export default function About()
    {

        return(
            <main id="About">
                <h1>A Propos</h1>
                <p>Le but de ce domaine est de regrouper tous les sites que je réalise en rapport avec le z80.</p>

                <Picture source={Matete} descritpion="Portrait de l'auteur" />

                <p>Je suis Amaury, aussi connu sous le nom de BDCIron, ancien démomaker sur Amstrad CPC 6128+ et passionné de retro-computing.</p>

                <p>Tout ceci a commencé par la création du site ASMTrad CPC ou j'avais décidé de créer des tutos pour apprendre à coder en assembleur sur Amstrad CPC.<br/>
                J'ai ensuite élargi le sujet au ZX Spectrum et souhaite désormais continuer sur un maximum de machines.</p>
                <p>Le but de tout ceci est de fournir à tous une documentation accéssible à un seul endroit sans avoir à chercher sur la toile la moindre information.</p>
                <p>Je suis parfois aidé par d'autres personnes qui participent en proposant des articles (qui sont les bien venus).</p>
                <p>Bien entendu la charge de travail pour réaliser tous ces sites est énorme et je ne peux malheureusement pas avancer à la vitesse que j'aimerai.<br/>
                Cependant, j'essaye d'avancer régulièrement et d'ajouter des articles intéressants le plus possible et sur les différentes machines.</p>
                <p>Le site ASMTrad CPC reste celui le plus développé car les Amstrad CPC sont les machines que je connais le mieux.</p>
                <p>Les prochains sites qui seront créés seront celui sur le VG5000 et les PCW.</p>
                <p>Ce site annuaire a été réalisé par moi même avec React.</p>
            </main>
        )
    }