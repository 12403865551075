import SlideShow from "../../components/SlideShow/SlideShow";
import './Home.scss'

export default function Home()
    {
        return (
            <>
                <main id="Annuaire">
                    <h1>Annuaire des sites</h1>
                    <SlideShow/>
                </main>
            </>
        )
    }