import React, { useState } from 'react';
import './SlideShow.scss';
import leftArrow from './assets/left_arrow.png';
import rightArrow from './assets/right_arrow.png'
import asmtradcpcImage from './assets/asmtradCPC.png'
import sinclairImage from './assets/sinclair.png'
import vgImage from './assets/vg5000.png'



export default function SlideShow ()
{
    const [siteNumber,setSiteNumber] = useState<number>(0)

    function leftClick() 
        {
        if (siteNumber === 0) {setSiteNumber(nombreDeSites)} 
        else {setSiteNumber(siteNumber - 1)}
        }

    function rightClick() 
        {
        if (siteNumber === nombreDeSites) {setSiteNumber(0)} 
        else {setSiteNumber(siteNumber + 1)}
        }

    interface sites 
        {
            site: string,
            url: string,
            description: string,
            siteImage: string
        }

    const SitesInfos:sites[] = 
        [
            {
                site : 'amstradCPC',
                url : 'https://asmtradcpc.zilog.fr',
                description : `Les Amstrad CPC sont des machines légendaires crées à la fois pour divertir ou pour travailler.<br/>
                <br/>
                Composés de 5 machines et d'une console, on estime leur vente à 3 millions d'exemplaires dans le monde, dont 1 million rien qu'en France.<br/>
                <br/>
                Dotés de capacités graphiques rares sur 8 bits, les CPCs disposent d'un écran bitmap pouvant afficher 16 couleurs sans contraintes.<br/>
                Leur processeur sonore Ay3-8912 est aussi utilisé sur d'autres machines dont les ZX Spectrum 128K, les Atari ST, l'Oric...<br/>
                Cet ordinateur est sans aucun doute celui qui en France aura le plus marqué sa génération.<br/>
                <br/>
                Bien qu'ayant de grandes capacités, les CPC ont souffert du portage des jeux venants du ZX Spectrum. Ceux-ci n'étants donc pas adaptés à la machine.<br/>
                <br/>
                Les CPC disposent pourtant d'un atout de taille avec son CRTC 6845, composant qui gère la structure de l'écran.<br/>
                Ce CRTC permet non seulement d'agrandir l'image et de diminuer le border (cadre) qui l'entoure, mais aussi de changer l'adresse de lecture de la RAM vidéo à chaque ligne.<br/>
                Le CPC peut faire des scrollings hard avec des décalages inférieurs au pixel.`,
                siteImage : `${asmtradcpcImage}`
            },
            {
                site : 'sinclairSpirit',
                url : 'https://sinclair.zilog.fr',
                description : `Machine légendaire de Sinclair, le spectrum a été diffusé dans une large partie du monde.<br/>
                On estime le nombre de machines vendues hors clones (car il y en a eu beaucoup dans les pays de l'est) à 9 millions rien que pour les spectrums !!!<br/>
                <br/>
                Bien que la machine n'ait presque aucune capacité hardware, celle-ci profite d'un affichage rapide (2 fois plus qu'un cpc par exemple) 
                et d'un color clash (grille de 2 couleurs par blocs de 8*8 pixels) permettants un certain gain d'affichage.<br/>
                <br/>
                Après le rachat de Sinclair par Amstrad, les Zx Spectrum seront systématiquement équipés d'un AY pour le son et de 128Ko de Ram.<br/>
                <br/>
                On dénombre 11 machines hors clones en incluant les célèbres ZX80 et ZX81.`,
                siteImage : `${sinclairImage}`
            },
            {
                site : 'vg5000',
                url : 'https://vg5000.zilog.fr',
                description : `Vendue exclusivement en France cette machine a été très peu diffusée.<br/>
                <br/>
                Doté de capacités techniques très faibles, la machine dispose en plus de peu de RAM: 24Ko. Cette RAM est en plus amputée de 8Ko utilisés par l'écran.<br/>
                Heureusement une interface (la VG 5216) permet d'ajouter 16Ko.<br/>
                <br/>
                Son clavier vous fera penser à celui des Minitel et c'est tout à fait logique puisque ce sont les mêmes touches.<br/>
                Mais ce n'est pas le seul point commun puisque le VG5000 possède le même processeur graphique, le EF9345 de Thomson.<br/>
                C'est donc une machine assez limitée graphiquement bien que certains modes graphiques pourraient surprendre.
                `,
                siteImage : `${vgImage}`
            }
        ]
    
    const tableSites = ['amstradCPC','sinclairSpirit','vg5000']
    const nombreDeSites:number = tableSites.length-1;
    let infos = SitesInfos.find(element => element.site === tableSites[siteNumber])
    let urlSite = infos!.url
    let siteImg = infos!.siteImage
    let infosTexte = infos!.description

    const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

    return(
        <>
            <div id='SlideShow'>
                <button id='SlideShow_arrow_left' onClick={leftClick}>
                    <img src={leftArrow} alt='left arrow' />
                </button>

                <div id='SlideShow_view'>
                    <a href={urlSite} target="_blank" >
                        <img src={siteImg} alt='link ton site'/>
                    </a>
                    <a id='SlideShow_view_vitre' href={urlSite} target="_blank"></a>
                </div>

                <button id='SlideShow_arrow_right' onClick={rightClick}>
                    <img src={rightArrow} alt='right arrow' />
                </button>
            </div>

            <div id='Infos'>{renderHTML(infosTexte)}</div>
        </>
    )
}