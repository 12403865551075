import Logo from "../Logo/Logo"
import NavBar from "../NavBar/NavBar"

export default function Header()
    {
        return (
            <>
                <header>
                    <Logo/>
                    <NavBar/>
                </header>
            </>
        )
    }